import React from 'react';
import Layout from '../layout';
import styled from 'styled-components';
import styling from '../../styling';
import { Page } from '../layout/header';
import { Link } from 'gatsby';

const StyledMain = styled.main`
  padding: 2em 0;
`;

// only render for screen readers
const StyledH1 = styled.h1`
  margin: 0;
  height: 0;
  overflow: hidden;
`;

const StyledResourcesLink = styled(Link)`
  margin: 0 -1em;
  padding: 1em;
  display: block;
  transition: ${styling.miscellaneous.transition};

  > h2 {
    margin: 0;
  }

  &:hover {
    background-color: ${styling.colors.primary.L95};
  }
`;

interface Props {
  location: Location;
  data: {
    allMdx: {
      edges: {
        node: Resources;
      }[];
    };
  };
}

const ResourcesPage = (props: Props) => {
  const resourcesList = props.data.allMdx.edges.map((e) => e.node);
  return (
    <Layout title="Book Notes" currentPage={Page.Resources} location={props.location}>
      <StyledMain>
        <StyledH1>Book Notes</StyledH1>
        {resourcesList.map((resources) => (
          <StyledResourcesLink to={`/resources/${resources.frontmatter.slug}`}>
            <h2>{resources.frontmatter.title}</h2>
          </StyledResourcesLink>
        ))}
      </StyledMain>
    </Layout>
  );
};

export default ResourcesPage;
