import { graphql } from 'gatsby';
import ResourcesPage from '../components/resourcesList';

export default ResourcesPage;

export const query = graphql`
  {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/resources/.*/resources.md/" }
        frontmatter: { draft: { eq: false }, obsolete: { eq: false } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            draft
            obsolete
            slug
            slugSynonyms
          }
        }
      }
    }
  }
`;
